import React from "react";

import { Link } from "react-router-dom";
import ResumeDownload from "./ResumeDownload";

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
  return (
    // add on resize listener
    <div className="site-page-content">
      {/* <img src={me} style={styles.topImage} alt="" /> */}
      <h1 style={{ marginLeft: -16 }}>Welcome</h1>
      <h3>I'm Holman Song</h3>
      <br />
      <div className="text-block">
        <p>
          I'm a software engineer currently working in·de·pend·ent·ly! I
          graduated from San Jose State University with my BS in Software
          Engineering.
        </p>
        <br />
        <p>
          Thank you for taking the time to check out my portfolio. I really hope
          you enjoy exploring it as much as I enjoyed building it. If you have
          any questions or comments, feel free to contact me using{" "}
          <Link to="/contact">this form</Link> or shoot me an email at{" "}
          <a href="mailto:holmansong1@gmail.com">holmansong1@gmail.com</a>
        </p>
      </div>
      <ResumeDownload />
      <div className="text-block">
        <h3>Here's my story.</h3>
        <br />
        <p>
          I’ve embarked on an exhilarating journey through the ever-evolving
          landscape of software engineering. Let me take you behind the scenes
          and share my story—a tale of passion, challenges, and relentless
          pursuit of innovation.
        </p>
        <br />
        {/* <div className="captioned-image">
                    <img src={me} style={styles.image} alt="" />
                    <p>
                        <sub>
                            <b>Figure 1:</b> A real photo of me developing this
                            website :)
                        </sub>
                    </p>
                </div> */}

        <p>
          As an independent software engineer, I’ve donned the mantle of a
          digital architect. My mission? To create a full-stack underwriting web
          application—a fortress guarding Electric Vehicle (EV) protection
          plans. Picture this: sleek lines of code weaving together a safety net
          for EV owners. The Continuity Compass v0.1, my trusty companion,
          ensures compliance with USWDS standards. JavaScript, React, Next.js,
          Python—these are my tools. Agile methodologies guide my steps. And as
          I code, I imagine the hum of electric engines and the promise of a
          cleaner future.
        </p>
        <br />
        <p>
          At InterviewRocket, I stepped into a realm where real-time meets AI.
          Imagine a platform—Interviewpractice.com—where job seekers hone their
          skills through mock interviews. My fingerprints are etched into its
          very fabric. The frontend dances with Next.js, while Python
          orchestrates the backend symphony. WebSockets weave seamless
          conversations between users and AI interviewers. As I code, I envision
          nervous candidates, their hearts racing, preparing for their next big
          opportunity.
        </p>
        <br />
        <p>
          Gateway v2 Protocol (Gv2)—a name that echoes through the corridors of
          decentralization. Rust, TypeScript, and exhaustive documentation—the
          ingredients of a revolution. I spearheaded this project, collaborating
          closely with our CTO. Together, we achieved 100% unit test code
          coverage. The Gateway Passes, once rigid, now dance to a new tune.
          Imagine a world where trust is decentralized, where identity is owned
          by the individual. As I code, I glimpse a future where sovereignty
          meets security.
        </p>
        <br />
        <p>
          Picture San José State students—3,000 strong—frantically searching for
          parking spots. Chaos reigns until my cross-platform parking app steps
          in. I, the lead engineer, crafted an interface that guides them
          seamlessly. Login screens, navigation markers, real-time tracking—the
          app whispers directions to lost souls. As I code, I see harried
          students breathe sighs of relief, finding their parking oasis. The app
          becomes their silent companion, easing their daily battles.
        </p>
        <br />

        <br />
        <div style={{}}>
          <div
            style={{
              flex: 1,
              textAlign: "justify",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            <h3>Before you go.</h3>
            <br />
            <p>
              As I write this, my fingers dance across the keyboard, shaping the
              next chapter. The digital world awaits, hungry for solutions. I’m
              Holman Song, and my code is my legacy. If you’re reading this,
              know that we’re connected—across time zones, screens, and lines of
              code. Let’s build dreams together, one commit at a time.
            </p>
            <br />
          </div>
          {/* <div style={styles.verticalImage}>
                        <img src={meNow} style={styles.image} alt="" />
                        <p>
                            <sub>
                                <b>Figure 2:</b> Me, April 2022
                            </sub>
                        </p>
                    </div> */}
        </div>
        <br />

        <p>
          If you have any questions or comments I would love to hear them. You
          can reach me through the <Link to="/contact">contact page</Link> or
          shoot me an email at{" "}
          <a href="mailto:holmansong1@gmail.com">holmansong1@gmail.com</a>
        </p>
      </div>
    </div>
  );
};

const styles: StyleSheetCSS = {
  contentHeader: {
    marginBottom: 16,
    fontSize: 48,
  },
  image: {
    height: "auto",
    width: "100%",
  },
  topImage: {
    height: "auto",
    width: "100%",
    marginBottom: 32,
  },
  verticalImage: {
    alignSelf: "center",
    // width: '80%',
    marginLeft: 32,
    flex: 0.8,

    alignItems: "center",
    // marginBottom: 32,
    textAlign: "center",
    flexDirection: "column",
  },
};

export default About;

import React, { useState } from "react";
import { useNavigate } from "react-router";
import software from "../../assets/pictures/projects/software.gif";

export interface ProjectsProps {}

interface ProjectBoxProps {
  icon: string;
  title: string;
  subtitle: string;
  url: string; // Changed from 'route' to 'url'
  iconStyle: React.CSSProperties;
}

const ProjectBox: React.FC<ProjectBoxProps> = ({
  icon,
  title,
  subtitle,
  url, // Use 'url' instead of 'route'
  iconStyle,
}) => {
  const [, setIsHovering] = useState(false);

  const handleClick = () => {
    window.open(url, "_blank"); // Opens the URL in a new tab
  };

  const onMouseEnter = () => {
    setIsHovering(true);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      onMouseDown={handleClick}
      className="big-button-container"
      style={styles.projectLink}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div style={styles.projectLinkLeft}>
        <img
          src={icon}
          style={Object.assign({}, styles.projectLinkImage, iconStyle)}
          alt=""
        />
        <div style={styles.projectText}>
          <h1 style={{ fontSize: 48 }}>{title}</h1>
          <h3>{subtitle}</h3>
        </div>
      </div>
      <div style={styles.projectLinkRight}></div>
    </div>
  );
};

const Projects: React.FC<ProjectsProps> = (props) => {
  return (
    <div className="site-page-content">
      <h1>Projects</h1>

      <br />
      <p>
        Click on one of the areas below to check out some of my favorite
        projects I've done in that field. Enjoy!
      </p>
      <br />
      <div style={styles.projectLinksContainer}>
        <ProjectBox
          icon={software}
          iconStyle={styles.computerIcon}
          title="Voxtodo"
          subtitle="Todo list app for those who are struggling with executive functioning issues"
          url="http://www.voxtodo.com" // Provide the full URL including https://
        />
        <ProjectBox
          icon={software}
          iconStyle={styles.computerIcon}
          title="AmberCare"
          subtitle="EV protection plan underwriting application"
          url="https://www.getamber.com" // Provide the full URL including https://
        />
        <ProjectBox
          icon={software}
          iconStyle={styles.computerIcon}
          title="ContinuityCompass v0.1"
          subtitle="USWDS Compliance Checker"
          url="https://continuity-compass-v01.vercel.app/"
        />
        <ProjectBox
          icon={software}
          iconStyle={styles.computerIcon}
          title="InterviewPractice"
          subtitle="AI-Powered Interview Training"
          url="https://www.interviewpractice.com/"
        />
        <ProjectBox
          icon={software}
          iconStyle={styles.computerIcon}
          title="IDENFT"
          subtitle="NFT Marketplace"
          url="https://www.idenfts.com/"
        />
        <ProjectBox
          icon={software}
          iconStyle={styles.computerIcon}
          title="Gateway Protocol v0.2"
          subtitle="Identity.com On-chain Identity Gateway monorepo"
          url="https://github.com/identity-com/on-chain-identity-gateway"
        />
        <ProjectBox
          icon={software}
          iconStyle={styles.computerIcon}
          title="Pistachio"
          subtitle="THE AUTOMATED DONATION APP"
          url="https://devpost.com/software/pistachio"
        />
        <ProjectBox
          icon={software}
          iconStyle={styles.computerIcon}
          title="DCMS"
          subtitle="CMS built on the blockchain"
          url="https://github.com/holmansong/DCMS"
        />
        <ProjectBox
          icon={software}
          iconStyle={styles.computerIcon}
          title="SpotMe Solutions"
          subtitle="PARKING MADE EASY"
          url="https://github.com/holmansong/SpotMeApplication"
        />
      </div>
    </div>
  );
};

const styles: StyleSheetCSS = {
  projectLinksContainer: {
    flexDirection: "column",
    width: "100%",
    display: "flex",
    flex: 1,
  },
  projectLink: {
    marginBottom: 24,
    cursor: "pointer",
    width: "100%",
    boxSizing: "border-box",

    alignItems: "center",
    justifyContent: "space-between",
  },
  projectText: {
    justifyContent: "center",
    flexDirection: "column",
  },
  projectLinkImage: {
    width: 48,
    marginRight: 38,
  },
  projectLinkLeft: {
    marginLeft: 16,
    alignItems: "center",
  },
  computerIcon: {
    width: 56,
    height: 56,
  },
  musicIcon: {
    width: 48,
    height: 48,
  },
  arrowIcon: {
    width: 48,
    height: 48,
  },
  artIcon: {
    width: 21 * 2,
    height: 37 * 2,
  },
};

export default Projects;

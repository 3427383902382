import React from "react";
import ResumeDownload from "./ResumeDownload";

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
  return (
    <div className="site-page-content">
      <ResumeDownload />
      <div style={styles.headerContainer}>
        <div style={styles.header}>
          <div style={styles.headerRow}>
            <h1>Independent Software Engineer</h1>
            <b>
              <p>Nov 2023 - Current</p>
            </b>
          </div>
        </div>
      </div>
      <div className="text-block">
        <p>
          Led the development of a full-stack underwriting web application,
          serving as the Minimum Viable Product (MVP) for an Electric Vehicle
          (EV) protection plan. Created a diverse range of web applications and
          tools, such as the Continuity Compass v0.1, designed specifically for
          ensuring compliance with USWDS standards.
        </p>
        <br />
        <p>Stack: Javascript, React, Next.js, Python, CSS, Git, Figma, Agile</p>
      </div>
      <div style={styles.headerContainer}>
        <div style={styles.header}>
          <div style={styles.headerRow}>
            <h1>InterviewRocket</h1>
            <a
              rel="noreferrer"
              target="_blank"
              href={"https://interviewpractice.com"}
            >
              <h4>www.interviewpractice.com</h4>
            </a>
          </div>
          <div style={styles.headerRow}>
            <h3>Software Engineer</h3>
            <b>
              <p>Jan 2023 - Dec 2023</p>
            </b>
          </div>
        </div>
      </div>
      <div className="text-block">
        <p>
          Developed the front-end and back-end of Interviewpractice.com using
          Next.js and Python, creating an intuitive user interface for
          real-time, AI-powered mock interviews.
        </p>
        <br />

        <p>
          Seamlessly integrated frontend with Python backend via WebSockets,
          ensuring smooth user interactions and conversational flow with AI
          agents.
        </p>
        <br />
        <p>Stack: Javascript, React, Next.js, Python, CSS, Git, REST APIs</p>
      </div>
      <div style={styles.headerContainer}>
        <div style={styles.header}>
          <div style={styles.headerRow}>
            <h1>Identity</h1>
            <a target="_blank" rel="noreferrer" href={"https://identity.com/"}>
              <h4>www.identity.com</h4>
            </a>
          </div>
          <div style={styles.headerRow}>
            <h3>Software Engineer Intern</h3>
            <b>
              <p>May 2022 - Jan 2023</p>
            </b>
          </div>
        </div>
      </div>
      <div className="text-block">
        <p>
          Spearheaded the development of the Gateway v2 Protocol (Gv2), a
          decentralized and semi-trustless system that manages how Gateway
          Passes work, encompassing a Rust program, TypeScript client service,
          and exhaustive documentation.
        </p>
        <br />

        <p>
          Worked closely with CTO to achieve 100% unit test code coverage for
          the DID-SOL, CryptId and Gv2 projects using Mocha and Jest.
        </p>
        <br />
        <p>
          Stack: Javascript, Typescript, React, CSS, Git, Jest, Mocha, Jira,
          Agile
        </p>
      </div>

      <div style={styles.headerContainer}>
        <div style={styles.header}>
          <div style={styles.headerRow}>
            <h1>SpotMeSolutions</h1>
          </div>
          <div style={styles.headerRow}>
            <h3>Software Engineer Intern</h3>
            <b>
              <p>May 2018 - Sep 2019</p>
            </b>
          </div>
        </div>
      </div>
      <div className="text-block">
        <p>
          Led the development of a cross-platform parking app, used by over
          3,000 San José State students, to help users find parking spots in
          real-time.
        </p>
        <br />
        <p>
          Designed and implemented the user interface, including login, button
          drawer navigation, markers, and location tracking using Google Maps
          API.
        </p>
        <br />
        <p>Stack: Javascript, React, CSS, Git</p>
      </div>
    </div>
  );
};

const styles: StyleSheetCSS = {
  header: {
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  skillRow: {
    flex: 1,
    justifyContent: "space-between",
  },
  skillName: {
    minWidth: 56,
  },
  skill: {
    flex: 1,
    padding: 8,
    alignItems: "center",
  },
  progressBar: {
    flex: 1,
    background: "red",
    marginLeft: 8,
    height: 8,
  },
  hoverLogo: {
    height: 32,
    marginBottom: 16,
  },
  headerContainer: {
    alignItems: "flex-end",
    width: "100%",
    justifyContent: "center",
  },
  hoverText: {
    marginBottom: 8,
  },
  indent: {
    marginLeft: 24,
  },
  headerRow: {
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
};

export default Experience;
